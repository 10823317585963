.share-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 1.5rem 4rem;
  min-height: 100vh;
  background-color: #fff; /* Added for a clean background */
}

.share-content {
  max-width: 600px;
  width: 100%;
  background-color: #ffe8d6;
  padding: 2.5rem 2rem;
  border-radius: 16px;
  text-align: center;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08); /* Added for depth */
  animation: fadeIn 0.5s ease-in; /* Added for modern entry effect */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.share-content h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primary-text);
  margin-bottom: 1rem;
}

.share-description {

  color: var(--secondary-text);
  margin-bottom: 2.5rem;
  line-height: 1.3;
}

.share-description.compact {
  margin-bottom: 1.5rem;
}

.share-link-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem; /* Increased for better spacing with icons */
  margin-bottom: 2rem;
}

.share-link {
  background-color: white;
  color: #d45100;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  border-radius: 8px;
  display: inline-block;
  word-break: break-word;
  max-width: 100%;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

/* Platform buttons container */
.platform-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

/* Button base style */
.social-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  transition: transform 0.2s ease;
}

.social-btn:hover {
  transform: scale(1.1);
}

/* Platform-specific colors */
.social-btn.twitter {
  background-color: #000000;
}

.social-btn.facebook {
  background-color: #4267B2;
}

.social-btn.whatsapp {
  background-color: #25D366;
}

.social-btn.device-share {
  background-color: #333;
}

.share-footer {
  font-size: 0.95rem;
  margin-top: 2rem;
  color: var(--muted-text);
}

/* Removed unused styles */