.profile-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.profile-container h1 {
  font-size: 2.5rem;
  color: #212529;
  margin-bottom: 20px;
}

.profile-card {
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  margin-bottom: 30px;
  text-align: left;
}

.profile-card h2 {
  font-size: 1.8rem;
  color: #212529;
  margin-bottom: 15px;
}

.profile-card p {
  font-size: 1.2rem;
  color: #495057;
}

.btn-edit {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: var(--travel-color);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-edit:hover {
  background-color: var(--hover-color);
}

.profile-actions {
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  text-align: left;
}

.profile-actions h2 {
  font-size: 1.8rem;
  color: #212529;
  margin-bottom: 15px;
}

.profile-actions p {
  font-size: 1.2rem;
  color: #495057;
}

.btn-access {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: var(--travel-color);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-access:hover {
  background-color: var(--hover-color);
}
.profile-card form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.profile-card input[type="email"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1rem;
  width: 100%;
  max-width: 400px;
}

.profile-card form button {
  padding: 10px 20px;
  background-color: var(--travel-color);
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.profile-card form button:hover {
  background-color: var(--hover-color);
}

.report-list {
  list-style: none;
  padding-left: 0;
  margin-top: 15px;
}

.report-list li {
  padding: 10px 0;
  border-bottom: 1px solid #eaeaea;
}

.report-list li a {
  color: #0077cc;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.report-list li a:hover {
  color: #004b99;
}
