.success-container {
  padding: 30px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.success-container h1 {
  color: #353535;
  max-width: 500px !important;
  margin-bottom: 20px;
  font-weight: 700 !important; 
  font-size: clamp(2.5rem, 5vw, 3.4rem) !important;
}

.success-container h2 {
  font-size: 1rem !important;
  color: #5e6164;
  font-weight: 400 !important;
  margin-bottom: 0.5rem;
  letter-spacing: 0.05rem;
}

.personality-image {
  max-width: 150px;
  height: auto;
  margin-bottom: 0;
  border-radius: 10px;
}

.feedback-container {
  background-color: #F5F6FA;
  padding: 20px;
  margin: 20px auto;
  border-radius: 12px;
  max-width: 500px;
}

.feedback-container p {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 600 !important;
}

.feedback-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.yes-button {
  background-color: #1e936b ;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
}

.no-button {
  background-color: #c1272d ;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
}

.download-info {
  font-size: 1rem;
  margin-bottom: 0 !important;
  text-align: center;
  color: #5e6164;
  font-weight: 400 !important;
}

.download-container {
  width: 100%; /* Make the container full width */
  display: flex;
  justify-content: center; /* Center the button */
  margin-top: 2rem;
}

.download-button {
  width: 100%; /* Make the button take the full width of its container */
  max-width: 500px; /* Prevent it from becoming too large on bigger screens */
  background-color: #FF5733;
  color: #fff;
  font-size: 1.2rem;
  padding: 1rem 2.5rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.download-button:hover {
  background-color: #e64e2d;
}

