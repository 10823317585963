/* Container centering the complete page content */
.test-complete-wrapper {
  background: #fff; /* Changed background to white */
}

/* Inner container for content */
.test-complete-container {
  text-align: center;
  background: #fff; /* White card effect */
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  max-width: 700px;
  margin: auto;
  margin-top: 0;
}
.cta-button.disabled {
  opacity: 0.7;
  pointer-events: none;
  cursor: wait;
}

.spinner {
  display: inline-block;
  margin-right: 8px;
  width: 14px;
  height: 14px;
  border: 2px solid transparent;
  border-top-color: white;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
  vertical-align: middle;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Success Icon Styling */
.success-icon {
  width: 150px;  /* Adjust size as needed */
  max-width: 100%; /* Ensures the image is fluid */
  margin-bottom: 20px;
}

/* Navigation Buttons */
.navigation-buttons {
  margin-top: 20px;
}

/* Navigation button styling */
.test-complete-container .navigation-buttons button {
  padding: 15px 30px;
  background-color: #ff5733; /* Orange background color */
  font-size: 18px;
  width: 100%;
  max-width: 500px;
  margin-top: 1px;
}

/* Edit My Answers Link */
.edit-answers {
  margin-top: 15px;
  font-size: 14px;
  font-weight: bold;
  color: rgb(114, 113, 113);
  cursor: pointer;
}

/* Specific styling for the edit-answers link */
.test-complete-container .edit-answers a {
  text-decoration: underline;
  font-weight: bold;
  color: #63626a;
  cursor: pointer;
}

/* Congratulations Heading */
.congratulations-heading {
  font-weight: bold; 
  margin-bottom: 15px;
}
@media (max-width: 450px) {
  .congratulations-heading {
    font-size: 28px !important; /* Use a lighter weight than bold (typically 700) */
  }
}
@media (max-width: 480px) {
  .navigation-buttons {
    margin-bottom: 0 !important;
    margin-top: 0 !important; 
  }
  /* Or tweak .edit-answers margin as well */
  .edit-answers {
    margin-top: 0 !important;
  }
}