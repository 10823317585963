/* Container styling */
.terms-container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

/* Headings styling */
.terms-container h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  text-align: left;
  font-weight: 600;
}

.terms-container h2 {
  margin-top: 30px;
  font-size: 1.5em;
  border-bottom: 2px solid #eee;
  padding-bottom: 5px;
  font-weight: 600;
}

.terms-container h3 {
  margin-top: 20px;
  font-size: 1.2em;
  color: #444;
}

/* Paragraphs and links */
.terms-container p {
  margin: 15px 0;
}

.terms-container a {
  color: #1a73e8;
  text-decoration: none;
}

.terms-container a:hover {
  text-decoration: underline;
}

/* Lists */
.terms-container ul,
.terms-container ol {
  margin: 15px 0 15px 20px;
}

/* Specific styling for copyright */
.terms-container .copyright {
  text-align: right;
  color: #888; /* Unique shade of grey */
  margin-top: 30px;
  font-size: 0.9em;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .terms-container {
    padding: 15px;
  }
  
  .terms-container h1 {
    font-size: 1.8em;
  }
  
  .terms-container h2 {
    font-size: 1.4em;
  }
  
  .terms-container h3 {
    font-size: 1.1em;
  }
}
