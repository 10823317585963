/* ===============================
   LOADER ANIMATION STYLES (UNCHANGED)
   =============================== */

   .results-loader {
    text-align: center;
    position: relative;
    padding: 2rem 0;
  }
  
  /* Big circle container */
  .big-circle-container {
    position: relative;
    margin: 0 auto;
    width: 250px;
    height: 250px;
    border: 2px solid #ccc;
    border-radius: 50%;
    background: #f9f9f9;
    overflow: visible;
  }
  
  /* Connector SVG */
  .connector-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    height: 250px;
    z-index: 1;
  }
  
  .connector-line {
    stroke: rgba(153, 153, 153, 0.3);
    stroke-width: 0.5;
  }
  
  /* Small circles */
  .small-circle {
    border-radius: 50%;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.3s ease;
    z-index: 2;
    width: 20px;
    height: 20px;
    /* Add these flex properties */
    display: flex;
    align-items: center;
    justify-content: center;
    /* Remove line-height if it exists */
    padding: 0 !important; /* Ensure no padding interferes */
    margin: 0 !important;
    line-height: 1; /* Add this to reset default line-height */
    padding-bottom: 3.5px;
    position: absolute; /* Add this for potential font metric adjustment */
    transform: translateY(-0.5px); /* Micro-adjustment for optical centering */
  }
  
  .small-circle.active {
    transform: scale(1.3);
  }
  /* Add this optional adjustment if needed for specific fonts */
.small-circle::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -52%); /* Fine-tune vertical alignment */
  width: 100%;
  height: 100%;
  pointer-events: none; /* Ensure clicks pass through to the element */
}
  
  /* Info container inside the big circle */
  .info-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 80%;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* Personality aspects carousel */
  .aspect-carousel {
    position: relative;
    height: 40px;
    overflow: hidden;
  }
  
  .aspect-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .aspect-item.active {
    opacity: 1;
    transform: translateY(0);
  }
  
  .aspect-icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
  
  /* Skeleton lines */
  .skeleton-lines {
    margin-top: 10px;
  }
  
  .skeleton-line {
    height: 8px;
    background: #ddd;
    border-radius: 4px;
    margin: 4px 0;
    animation: shimmer 1.5s infinite;
    background: linear-gradient(90deg, #ddd 25%, #e7e7e7 50%, #ddd 75%);
    background-size: 400px 100%;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -400px 0;
    }
    100% {
      background-position: 400px 0;
    }
  }
  
  /* Progress container & progress bar 
  .my-progress-container {
    width: 100%;
    max-width: 400px;
    height: 6px;
    background: #e2e8f0;
    border-radius: 3px;
    margin: 2rem auto 1rem;
    display: block;
    position: relative;
    overflow: visible !important;
  }
  
  .my-progress-bar {
    height: 100%;
    background: #ff5733;
    border-radius: 3px;
    width: 0%;
    position: absolute;
    left: 0;
    top: 0;
    will-change: width;
  }*/

  /* Progress Bar Styles */
.my-progress-container {
  width: 100%;
  max-width: 400px;
  height: 6px;
  background: #e0e0e0;
  border-radius: 3px;
  overflow: hidden;
  margin: 2rem auto 1rem;
}

.my-progress-bar {
  width: 0%;
  height: 100%;
  background: #ff5733;
  animation: stutterAndAccelerate 9s forwards;
}

@keyframes stutterAndAccelerate {
  0% { width: 0%; }
  15% { width: 10%; animation-timing-function: step-end; }
  20% { width: 10%; }
  35% { width: 25%; animation-timing-function: step-end; }
  40% { width: 25%; }
  55% { width: 45%; animation-timing-function: step-end; }
  60% { width: 45%; }
  75% { width: 70%; animation-timing-function: step-end; }
  80% { width: 70%; }
  100% { width: 100%; }
}
.waiting-progress-container {
  width: 100%;
  max-width: 400px;
  height: 6px;
  background: #e0e0e0;
  border-radius: 3px;
  overflow: hidden;
  margin: 2rem auto 1rem;
  position: relative;
}

.waiting-progress-bar {
  position: absolute;
  height: 100%;
  width: 30%;
  background: #ff5733;
  border-radius: 3px;
  animation: slideLoop 1.6s linear infinite;
}

/* Slide the bar across from left to right */
@keyframes slideLoop {
  0% {
    left: -30%;
    opacity: 0.4;
  }
  50% {
    left: 50%;
    opacity: 1;
  }
  100% {
    left: 100%;
    opacity: 0.4;
  }
}

/* Keep all your existing loader animation styles below */
  
  /* Progress meta text */
  .progress-meta {
    font-family: 'Poppins', sans-serif;
    color: #424242;
    font-size: 1rem;
    text-align: center;
    margin-top: 1rem;
  }
  