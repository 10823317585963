/* Consolidated CSS Variables */
:root {
  --travel-color: #ff6f61;
  --primary-text: #212529;
  --secondary-text: #495057;
  --muted-text: #6c757d;
  --light-bg: #F5F6FA;
  --border-color: #fa7101;
  --x-gap: 1rem;
  --x: 3rem;
}

/* Global Styles */
html, body {
  overflow-x: hidden;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  max-width: 1200px;
}

/* ---------------------------
   HERO SECTION
---------------------------- */
.hero-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}

.home-content {
  background-color: #ffe8d6;
  padding: 2.5rem 3rem;
  border-radius: 16px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: block;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  }

.hero-text {
  width: 100%;
}

/* Hero image – default (for xs/sm) appears below text */
.hero-image {
  width: 100%;
  margin-top: 1rem;
  display: block;
}
.hero-image img {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.7);
  border-radius: 43px 43px 0 0;
}

/* Hero text styling */
.hero-text h1 {
  font-size: clamp(2rem, 5vw, 3.5rem);
  font-weight: bold;
  color: var(--primary-text);
  line-height: 1.2;
  margin-bottom: 1rem;
}
.hero-text .highlight {
  color: var(--travel-color);
}
.hero-text p {
  font-size: 1.6rem;
  color: var(--primary-text);
  margin-bottom: 4rem;
}
.hero-text .terms {
  font-size: 0.9rem;
  color: var(--muted-text);
  margin-top: 1.5rem;
  margin-bottom: 0;
  text-align: left;
}
.terms a {
  color: var(--muted-text);
  text-decoration: none;
}

/* Uniform Button Styling with Focus for Accessibility */
.btn-start {
  background-color: var(--travel-color);
  color: #fff;
  padding: 1rem 2.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}
.btn-start:hover {
  background-color: #e64e2d;
}

/* ---------------------------
   IMAGE SECTION (phone image)
---------------------------- */
.phone-image {
  width: 100%;
  max-width: 250px;
  height: auto;
  border-radius: 10px;
}

/* ---------------------------
   INFO SECTION
---------------------------- */
.info-section {
  margin: 3rem 0;
}
.info-item {
  text-align: center;
  margin-bottom: 2rem;
}
.info-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin: 0 auto 1rem;
  display: block;
}
.info-item h3 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--primary-text);
}
.info-item p {
  font-size: 1.2rem;
  color: var(--secondary-text);
  margin: 0 1rem;
}

/* ---------------------------
   STATS SECTION
---------------------------- */
.stats-section {
  margin: 1rem 0;
  padding: 0 var(--x-gap);
}
.stats-item {
  background-color: var(--light-bg);
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
}
.stats-highlight {
  font-size: 1.8rem;
  color: #2e2e2e;
  font-weight: normal;
  margin: 0;
}
.stats-number {
  font-size: clamp(3.8rem, 5vw, 4.5rem);
  font-weight: 400;
  color: var(--primary-text);
  margin-bottom: 0;
}
.stats-description {
  font-size: 1rem;
  color: var(--secondary-text);
  text-align: left;
  margin-top: 0;
}

/* ---------------------------
   HOW IT WORKS SECTION
---------------------------- */
.how-it-works {
  background-color: var(--light-bg);
  border-radius: 16px;
  padding: 2rem var(--x);
  text-align: center;
  margin: 0.7rem 0;
}
.how-it-works h2 {
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 500;
  color: var(--primary-text);
  margin-bottom: 1.5rem;
}
.step {
  text-align: center;
  margin-bottom: 1.5rem;
}
.step-circle {
  width: 50px;
  height: 50px;
  background-color: #ffe8d6;
  color: var(--primary-text);
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  border: 1px solid var(--border-color);
}
.step h3 {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--primary-text);
  margin-bottom: 0.5rem;
}
.step p {
  font-size: 1rem;
  color: var(--secondary-text);
}
.btn-container {
  text-align: center;
  margin-top: 2rem;
}

/* ---------------------------
   WHAT YOU WILL RECEIVE SECTION
---------------------------- */
.receive-section {
  padding: 2rem 3rem 0;
  background-color: #ffffff;
  border-radius: 16px;
  margin: 2rem 0;
  text-align: center;
}
.receive-section h2 {
  font-size: clamp(1.8rem, 4vw, 2rem);
  font-weight: bold;
  color: var(--primary-text);
  margin-bottom: 2rem;
}
.receive-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}
.receive-item {
  flex: 1 1 250px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.icon-circle {
  width: 60px;
  height: 60px;
  background-color: var(--travel-color);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 1rem;
}
.icon-circle svg {
  font-size: 1.2rem;
}
.receive-text h3 {
  font-size: 1rem;
  font-weight: bold;
  color: var(--primary-text);
  margin: 0.6rem 0;
}
.receive-text p {
  font-size: 0.8rem;
  color: var(--secondary-text);
  margin: 0;
}

/* ---------------------------
   EXPERTS SECTION
---------------------------- */
.experts-section {
  margin: 2rem 0;
  padding: 2rem 3rem;
  background-color: #ffffff;
  border-radius: 16px;
}
.image-wrapper {
  text-align: center;
  margin-bottom: 1rem;
}
.experts-image {
  width: 100%;
  max-width: 500px;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.text-wrapper {
  text-align: left;
}
.text-wrapper h2 {
  font-size: 2rem;
  font-weight: 400;
  color: var(--primary-text);
  margin-bottom: 1rem;
}
.text-wrapper p {
  font-size: 1rem;
  color: var(--secondary-text);
  margin-bottom: 1rem;
}
.text-wrapper h3 {
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--secondary-text);
  margin: 2rem 0;
}

/* ---------------------------
   MEDIA QUERIES
---------------------------- */

/* Mobile-first adjustments (max-width: 767.98px) */
@media (max-width: 767.98px) {
  /* Hero Section */
  .hero-image {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
  }
  .home-content {
    padding-bottom: 400px;
    text-align: center;
  }
  
  /* Info Section Personalized Layout */
  .info-item--personalized {
    display: flex;
    flex-direction: column;
  }
  .info-item--personalized h3 {
    order: 1;
  }
  .info-item--personalized img {
    order: 2;
  }
  .info-item--personalized p {
    order: 3;
  }
  
  .hero-text .terms {
    text-align: center;
  }
  .stats-number {
    font-size: 3.5rem !important;
    font-weight: 400;
  }
  .stats-highlight {
    font-size: 1.8rem !important;
  }
  .receive-section {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .receive-container {
    flex-direction: column;
    gap: 0.5rem;
    align-items: stretch;
  }
  .receive-item {
    flex: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    padding: 0;
  }
  .icon-circle {
    flex: 0 0 60px;
    width: 60px;
    height: 60px;
    margin-right: 1rem;
    margin-bottom: 0;
    margin-top: 0;
  }
  .receive-text {
    flex: 1;
  }
  .receive-section h2 {
    text-align: left;
  }
  .receive-text h3 {
    margin-top: 0;
  }
}

/* Extra Small Screens Typography (max-width: 449.98px) */
@media (max-width: 449.98px) {
  h1 {
    font-size: 2.2rem !important;
    font-weight: 600 !important;
    line-height: 1.3 !important;
  }
  h2 {
    font-size: 1.7rem !important;
    font-weight: 600 !important;
    line-height: 1.3 !important;
  }
  h3 {
    font-size: 1.3rem !important;
    font-weight: 600 !important;
    line-height: 1.3 !important;
  }
  h4 {
    font-size: 1.1rem !important;
    font-weight: 600 !important;
    line-height: 1.3 !important;
  }
  p,
  .step p {
    font-size: 1rem !important;
    line-height: 1.5 !important;
  }
  .terms {
    font-size: 0.8rem !important;
    line-height: 0.8 !important;
  }
  .home-content {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    border-radius: 0;
  }
  .container-fluid,
  .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .how-it-works {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    border-radius: 0;
  }

}
@media (max-width: 768px) {
  .hero-text h1 {
    font-size: 2.1rem !important;
    font-weight: bold !important;
  }
}


/* Tablet adjustments (768px–1024px) */
@media (min-width: 767.98px) and (max-width: 1024px) {
  .hero-image {
    display: none;
  }

}

/* Large Screens (≥1025px) */
@media (min-width: 1025px) {
  .home-content {
    padding-right: calc(300px + clamp(20px, 5%, 40px));
  }
  .hero-image {
    position: absolute;
    right: clamp(20px, 5%, 40px);
    bottom: 0;
    width: 300px;
    margin-top: 0;
    display: block;
  }
}

/* Stats Section Flex Adjustments (≥992px) */
@media (min-width: 992px) {
  .stats-section .row {
    display: flex;
    align-items: stretch;
  }
  .stats-section .col-lg-7,
  .stats-section .col-lg-5 {
    display: flex;
    flex-direction: column;
  }
  .stats-section .col-lg-5 > .row {
    flex: 1;
    display: flex;
    align-items: stretch;
  }
  .stats-section .col-lg-5 > .row > [class*="col-"] {
    display: flex;
    flex-direction: column;
  }
  .stats-item {
    flex: 1;
  }

  
}

/* Receive Container Adjustments (≥768px) */
@media (min-width: 767.98px) {
  .receive-container {
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 1rem;
  }
}
