/* Full-page layout */
.share-compass-container {
  background-color: white;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
}

/* Main card box */
.share-compass-card {
  background: #FCE8D5;
  border-radius: 24px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  padding: 2.5rem 2rem;
  text-align: center;
  max-width: 480px;
  width: 100%;
  box-sizing: border-box;
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Title */
.share-compass-card h1 {
  font-size: 2rem;
  color: #000000;
  margin-bottom: 0.5rem;
}

/* Subtext under title */
.share-compass-card .subtext {
  font-size: 1rem;
  color: #444;
  margin-bottom: 1.8rem;
  line-height: 1.5;
}

/* Chart image */
.chart-image {
  width: 100%;
  max-width: 440px;
  height: auto;
  display: block;
  margin: 0 auto 1.5rem;
  border-radius: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  background-color: #fff7f2;
  border: 2px solid #F7591F;
}

/* Share section */
.share-section {
  margin-top: 1.2rem;
}

/* Headline before sharing options */
.cta-text {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
  color: #333;
}

/* Hashtag style */
.hashtag {
  font-size: 0.85rem;
  color: #888;
  margin-bottom: 1rem;
}

/* Platform buttons container */
.platform-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  margin-top: 0.8rem;
}

/* Button base style */
.social-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: white;
  text-decoration: none;
  transition: transform 0.2s ease;
}

.social-btn:hover {
  transform: scale(1.1);
}

/* Platform-specific colors */
.social-btn.twitter {
  background-color: #000000;
}

.social-btn.facebook {
  background-color: #4267B2;
}

.social-btn.whatsapp {
  background-color: #25D366;
}

.social-btn.instagram {
  background-color: #E1306C;
}

.social-btn.download {
  background-color: #F7591F;
}

.social-btn.device-share {
  background-color: #333;
}

/* Other existing styles */
.btn-share {
  background-color: #F7591F;
  color: white;
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 10px;
  font-weight: bold;
  font-size: 0.95rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.btn-share:hover {
  background-color: #e14f16;
}

.share-compass-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
  overflow-y: auto;
}

.cta-start {
  background-color: #F7591F;
  color: white;
  padding: 0.7rem 1.5rem;
  font-size: 1.05rem;
  border-radius: 12px;
  font-weight: 700;
  text-decoration: none;
  transition: background-color 0.25s ease;
  margin-bottom: 10px;
}

.cta-start:hover {
  background-color: #e14f16;
}

.cta-button {
  display: inline-block;
  background-color: var(--travel-color, #F7591F);
  color: #fff;
  padding: 0.9rem 2rem;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 700;
  text-decoration: none;
  transition: background-color 0.3s ease;
  margin-bottom: 5px;
  margin-top: 0;
}

.cta-button:hover {
  background-color: #e64e2d;
}

@media (max-height: 700px) {
  .share-compass-page {
    align-items: flex-start;
  }
}