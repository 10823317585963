/* Hide header during loading phase */
.loading-screen ~ .custom-header {
  display: none !important;
}
/* Hide header completely during loading phase */
.loading-screen {
  position: relative;
  z-index: 9999; /* Ensure loading screen appears above header */
}

.loading-screen::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: -1;
}

/* Hide header when loading screen is active */
body:has(.loading-screen) .custom-header {
  display: none !important;
}

/* src/components/StartTest.css */
.start-test-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
}

/* When loading, center all content */
.start-test-container.centered {
  justify-content: center;
}

/* When guidelines are shown, align content at the top */
.start-test-container.flex-start {
  justify-content: flex-start;
}

.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Progress Bar Container */
@keyframes stutterAndAccelerate {
  0% { width: 0%; }
  20% { width: 10%; }
  25% { width: 10%; }
  45% { width: 20%; }
  50% { width: 20%; }
  70% { width: 30%; }
  75% { width: 30%; }
  100% { width: 100%; }
}
.progress-bar-loading {
  width: 200px;
  height: 4px;
  background: #e0e0e0; /* Grey track color */
  border-radius: 2px;
  overflow: hidden;
  margin: 10px 0;
  margin-bottom: 1px;
}

.progress-bar-loading .progress {
  width: 0%;
  height: 100%;
  background: var(--progress-color, #ff5733);
  animation: stutterAndAccelerate 2s forwards;
}

.loading-text {
  color: #000000;
  font-family: 'Poppins', sans-serif;
  font-weight: 400; /* Regular weight */
  font-size: 1rem;
  margin-top: 0; /* Remove default paragraph spacing */
  margin-bottom: 0;
}

.guidelines-container {
  max-width: 600px;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-left: 0rem; /* Space for checkmarks */
  padding-right: 0rem;
  margin: 0 auto;
}

/* Test Guidelines Heading */
.guidelines-container h2 {
  font-size: 2.5rem; /* Increased from default */
  font-weight: 700; /* Bold */
  margin-bottom: 0rem;
  color: #1a1a1a;
  text-align: center;
}

.guidelines-list {
  list-style: none;
  padding-left: 0;
  margin: 2rem 0 0;
  text-align: left; /* Force left alignment */
  width: 100%; /* Take full container width */
}

.guidelines-list li {
  font-family: 'Poppins', sans-serif;
  font-weight: 400; /* Regular weight */
  font-size: 1rem;
  margin-bottom: 1.5rem;
  padding-left: 6rem;
  margin-left: 0;
  position: relative;
  line-height: 1.6;
  text-align: left; /* Explicit left alignment */
  max-width: 90%; /* Force text wrapping */
}
.guidelines-list li::before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 3.5rem;
  top: 0.15em; /* Adjusted vertical alignment */
  width: 1.5rem; /* Increased from 1.2rem */
  height: 1.5rem; /* Increased from 1.2rem */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ff5733'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z' stroke='%23ff5733' stroke-width='1'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 0;
}

/* Add/Update these styles */
.guidelines-icon {
  width: 140px;  /* Reduced from 80px */
  height: 140px; /* Reduced from 80px */
  margin: 0 auto 1rem;
}

.start-test-button {
  background-color: var(--travel-color);
  color: white;
  padding: 12px 35px;
  border: none;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: calc(75% + 0.5rem); /* Match text width + checkmark space */
  margin-left: 3.5rem; 
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.start-test-button:hover {
  background-color: var(--hover-color);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.logo-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.logo-spinner {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  /* Adjust heading size */
  .guidelines-container h2 {
    font-size: 1.8rem; /* Reduced from 2.5rem */
  }

  /* Expand list items width */
  .guidelines-list li {
    max-width: 100%; /* Full width instead of 90% */
    padding-left: 4rem; /* Reduced from 6rem */
    font-size: 1rem; /* Optional: Reduce text size if needed */
  }

  /* Adjust checkmark position */
  .guidelines-list li::before {
    left: 1.5rem; /* Align with reduced padding */
  }

  /* Center and expand button */
  .start-test-button {
    width: 100%; /* Full width */
    margin-left: 0; /* Remove previous margin */
    padding: 12px 25px; /* Wider button */
    max-width: 320px; /* Prevent over-expanding */
    margin: 0 auto; /* Center horizontally */
  }
}
