/* Overall Contact Page */
.contact {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
  padding: 2rem 1rem;
  background-color: #ffffff;
}

/* Main container for both sections */
.contact-container {
  display: flex;
  flex-direction: row; /* Side-by-side on larger screens */
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  width: 100%;
  gap: 2rem;
}

/* Responsive layout: stack vertically on small screens */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }
  .contact-info,
  .contact-form {
    width: 100%;
  }
  .contact {
    padding-top: 0; /* reduce top padding on small screens */
  }
}

/* Contact Info Section */
.contact-info {
  flex: 1;
  padding: 1rem;
}

.contact-info h1 {
  font-size: 2rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 1rem;
}

.contact-info p {
  font-size: 1rem;
  color: #495057;
  line-height: 1.6;
}

.contact-details h3 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #212529;
  margin-top: 2rem;
}

.contact-details p {
  font-size: 1rem;
  color: #495057;
  margin: 0.5rem 0;
}

.contact-icon {
  color: var(--travel-color);
  margin-right: 0.5rem;
}

/* Contact Form Section */
.contact-form {
  flex: 1;
  padding: 1rem;
}

.contact-form h1 {
  font-size: 2rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 1.5rem;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  color: #495057;
  outline: none;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: var(--travel-color);
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}

.contact-form button {
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: var(--travel-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #e64e2d;
}

/* Optional: Remove extra space at the bottom */
.contact {
  margin-bottom: 0;
  padding-bottom: 0;
}

/* Footer adjustments if needed */
footer {
  margin-top: 0;
  padding-top: 0;
}
