:root {
  --progress-color: #ff5733;
  --travel-color: #7fd1ae; /* For button consistency */
  --hover-color: #6ab89d;  /* For button hover */
  --circle-gap: 40px;      /* Shared gap for circles (desktop default) */
  --question-padding: clamp(5px, 5vw, 50px);
}

/* General Styling */
.test-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #F5F6FA;
}

.test-container {
  width: 90%;
  max-width: 1000px;
  margin: auto;
  padding: 20px 30px 50px; /* Top:20px, Sides:30px, Bottom:50px */
  font-family: "Poppins", sans-serif;
  position: relative;
}

/* Progress Bar */
.progress-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  font-size: 12px;
  color: #555;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.progress-bar {
  width: 100%;
  max-width: 700px;
  height: 7px !important;
  background: #D1D5DB !important;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

.progress-text {
  display: flex;
  align-items: baseline;
  gap: 2px;
}

.progress-value {
  font-weight: 700;
  font-size: 13px;
}

.progress {
  height: 100%;
  background: var(--progress-color) !important;
  transition: none;
}

/* Title */
.test-title {
  text-align: center;
  font-size: 20px;
  margin: 25px auto; /* Equal top/bottom margin */
  font-weight: bold;
  position: relative; /* For better spacing control */
  z-index: 1; /* Ensure it stays above absolute elements */
}

/* Rating Scale (Non‑clickable circles) */
.rating-scale {
  display: flex;
  justify-content: center;
  gap: var(--circle-gap);
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  padding: 0;
  margin-bottom: 65px;
  position: relative;
}

.rating-option {
  width: 40px; /* All rating options remain 40px wide */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rating-option .circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid;
}

.rating-option .rating-label {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-weight: bold;
  line-height: 1.4;
  font-size: 12px;
  white-space: nowrap;
}

/* 
  NEW OVERRIDE for First and Last Rating Option Labels:
  Allow the label to be wider (60px) so that "Strongly Disagree" and "Strongly Agree"
  can break into two lines while keeping their centers aligned with the 40px circle.
*/
.rating-scale .rating-option:first-child .rating-label,
.rating-scale .rating-option:last-child .rating-label {
  white-space: normal;   /* Allow wrapping */
  width: 60px;           /* Wider label container */
  left: 50%;
  transform: translateX(-50%); /* Keep the label centered relative to the 40px circle */
  text-align: center;
}

/* Questions */
.question-list {
  margin-top: 20px;
  width: 100%;
}



.question-item {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  max-width: 700px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
}

.question-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 400; /* Regular weight */
  font-size: 1rem;
  margin-bottom: 25px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Answer Options (Clickable circles) */
.selectable {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: transparent;
  cursor: pointer;
  transition: border-width 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

.selectable:hover {
  border-width: 3px;
  border-color: rgba(0, 0, 0, 0.5);
}

.selected {
  border-width: 3px;
  border-color: #000;
}

/* Clickable circles container */
.options {
  display: flex;
  justify-content: center;
  gap: var(--circle-gap);
}

/* Navigation Buttons */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.navigation-buttons button:last-child {
  display: block;
  width: 100%;
  max-width: 700px;
  margin: 20px auto 0;
  padding: 17px 0;
  background-color: var(--travel-color);
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.navigation-buttons button:last-child:hover {
  background-color: var(--hover-color);
}

/* Previous Button */
.previous-button-container {
  position: absolute;
  top: 90px; /* Increased from 70px to move down */
  left: calc((100% - min(700px, 90%)) / 2); /* Matches progress bar's left edge */
  transform: translateY(-50%);
  z-index: 10;
}

.previous-button {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #555;
  padding: 5px;
  margin: 0;
  z-index: 10;
}
.previous-button svg {
  pointer-events: none;
}
.previous-button:hover svg path {
  fill: var(--hover-color);
}

/* Error Message */
.error-message {
  color: #4c4c4c !important;
  font-size: 14px;
  text-align: center;
  margin: 10px 0;
}

/* Check Mark */
.check {
  width: 1.5rem;
  height: 1.5rem;
  fill: #000;
  margin-top: 5px;
}

/* Responsive Design */
@media (max-width: 767px) {
  :root {
    --circle-gap: clamp(
      8px, 
      calc(8px + (100vw - 360px) * 0.15), 
      60px
    )
  }
  .test-title {
    font-size: 18px !important;
  }
  }


/* Desktop scaling */
@media (min-width: 768px) {
  :root {
    --circle-gap: clamp(60px, 5vw, 80px);
  }
}

/* 360px adjustments (now uses dynamic gap) */
@media (max-width: 360px) {
  .test-container {
    padding: 10px;
  }
  .question-text {
    font-size: 13px;
  }
  /* Remove explicit gap declarations - now using var(--circle-gap) */
}

/* 480px adjustments (now uses dynamic gap) */
@media (max-width: 480px) {
  .test-container {
    padding: 15px;
  }
  .progress-header {
    font-size: 11px;
  }
  .progress-value {
    font-size: 12px;
  }
  .test-title {
    margin-bottom: 25px;
  }
  .rating-scale {
    flex-wrap: nowrap;
    overflow-x: visible;
  }
  .rating-option .rating-label {
    top: 40px;
    font-size: 11px;
  }
  .question-text {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .selectable {
    position: relative;
    flex-shrink: 0;
  }
  .previous-button-container {
    top: 100px !important;
  }
  .previous-button {
    font-size: 20px;
  }
  .navigation-buttons button:last-child {
    font-size: 19px;
    padding: 15px 0;
    margin-bottom: 30px;
  }
  .options {
    flex-wrap: nowrap;
    overflow-x: visible;
    padding: 0 8px;
  }

}
@media (max-width: 768px) {
    .test-container {
      padding: 20px;
    }
    .question-item {
      overflow: hidden;
    }
    .question-text {
      font-size: 15px;
    }
    .navigation-buttons {
      flex-direction: column;
      gap: 15px;
    }
    .previous-button-container {
      left: 15px;
      top: 120px;
    }
    /* For tablets, keep the desktop rating option sizes. */
    .rating-option {
      width: 40px;
    }
    .rating-option .circle {
      width: 40px;
      height: 40px;
    }
    .rating-option .rating-label {
      top: 50px;
      font-size: 12px;
    }
}
@media (min-width: 768px) {
  :root {
    --circle-gap: clamp(60px, 5vw, 80px);
  }
} 
.question-item {
  padding: 20px clamp(10px, 5vw, 50px); /* Top & bottom fixed at 20px, Left & right dynamic */
}

