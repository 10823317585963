/* Container styling */
.privacy-policy-container {
  max-width: 1176px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

/* Headings */
.privacy-policy-container h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  font-weight: 600;
  text-align: left;
}

.privacy-policy-container h2 {
  margin-top: 30px;
  font-size: 1.5em;
  border-bottom: 2px solid #eee;
  padding-bottom: 5px;
  font-weight: 600;
}

.privacy-policy-container h3 {
  margin-top: 20px;
  font-size: 1.2em;
  color: #444;
  font-weight: 600;
}

/* Paragraphs and links */
.privacy-policy-container p {
  margin: 15px 0;
}

.privacy-policy-container a {
  color: #1a73e8;
  text-decoration: none;
}

.privacy-policy-container a:hover {
  text-decoration: underline;
}

/* Lists */
.privacy-policy-container ul,
.privacy-policy-container ol {
  margin: 15px 0 15px 20px;
}

/* Table styling */
.table-container {
  overflow-x: auto;
  margin: 15px 0;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th,
.table-container td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}


.table-container th {
  background-color: #f2f2f2;
}

.privacy-policy-container .copyright {
  text-align: right;
  color: #888; /* Unique shade of grey */
  margin-top: 30px;
  font-size: 0.9em;
}


/* Responsive adjustments */
@media (max-width: 600px) {
  .privacy-policy-container {
    padding: 15px;
  }
  
  .privacy-policy-container h1 {
    font-size: 1.8em;
  }
  
  .privacy-policy-container h2 {
    font-size: 1.4em;
  }
  
  .privacy-policy-container h3 {
    font-size: 1.1em;
  }
  .table-container table {
    table-layout: fixed;
  }
}
