/* Pricing Page Styling */
.pricing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  margin-right: 1rem;
  margin-bottom: 0;
  margin-left: 1rem;
  padding: 0;
  background-color: #ffffff; /* Ensure consistency with other pages */
  border: none !important;
}

/* Pricing Top Section */
.pricing-top-section {
  max-width: 1140px ;
  width: 100%;
  background-color: #ffe8d6; /* Light peach background for emphasis */
  padding: 1.5rem 3rem;
  border-radius: 16px;
  text-align: center;
  margin-top: 0;
  box-sizing: border-box;
}

.pricing-top-section h1 {
  font-size: 2.73rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 1rem;
}

.pricing-top-section p {
  font-size: 1.2rem;
  color: #495057;
  line-height: 1.6;
  margin: 0;
}

/* Pricing Section */
.pricing-section {
  max-width: 1200px;
  width: 100%;
  margin: 3rem auto 2rem;
  text-align: center;
}

.pricing-title-page {
  font-size: 1.2rem;
  color: #6c757d;
  font-weight: bold;
  margin-bottom: 1.5rem;
  letter-spacing: 0.05rem;
}

.pricing-options {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
}

.pricing-card {
  flex: 1;
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 2rem;
  text-align: left;
}

.pricing-card h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 1rem;
}

.pricing-card p {
  font-size: 1rem;
  color: #495057;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.pricing-card ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 1.5rem 0;
  text-align: left;
}

.pricing-card ul li {
  margin-bottom: 0.5rem;
  color: #495057;
  display: flex;
  align-items: flex-start;
}

.pricing-card ul li::before {
  content: "✔";
  color: #28a745;
  margin-right: 0.5rem;
}

.pricing-card .price {
  font-size: 2rem;
  font-weight: bold;
  color: #212529;
  margin: 1.5rem 0;
}

.pricing-card button {
  background-color: var(--travel-color);
  color: white;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.pricing-card button:hover {
  background-color: #e64e2d;
}

.pricing-card.premium {
  background-color: #ffe8d6;
}

/* Why Us Section */
.why-us-section {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 16px;
}

.why-us-title {
  font-size: 1.2rem;
  color: #6c757d;
  font-weight: bold;
  margin-bottom: 0.5rem;
  letter-spacing: 0.05rem;
}

.why-us-section h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 2rem;
}

.why-us-cards {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  /* Default layout (desktop) will be adjusted via media queries */
  flex-wrap: wrap;
}

.why-us-card {
  flex: 1;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
}

.icon-circle-pricing {
  width: 70px;
  height: 70px;
  background-color: var(--travel-color);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  font-size: 1.5rem;
}

.icon-circle-pricing svg {
  font-size: 1.3rem; /* Adjust this value to decrease/increase the icon size */
}

.why-us-card h3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 0.5rem;
}

.why-us-card p {
  font-size: 1rem;
  color: #495057;
  line-height: 1.5;
}

/* Unlock Potential Section */
.unlock-potential-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 2rem;
  padding: 0 1rem;
}

.unlock-potential-content {
  flex: 1;
}

.unlock-potential-content h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #212529;
}

.unlock-potential-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.unlock-potential-content li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  color: #495057;
}

.unlock-potential-list li {
  position: relative;
  padding-left: 3rem; /* Ensure there’s room for the check mark */
  padding-right: 1rem;
}

.unlock-potential-list li::before {
  content: "✔";
  color: #28a745;
  position: absolute;
  left: 1rem;
  top: 0;
  margin-right: 0.5rem;
}

.unlock-potential-image {
  flex: 1;
}

.unlock-potential-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 12px;
}

/* Questions Section */
.questions-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 2rem auto;
  gap: 2rem;
  padding: 2rem 1rem;
}

.questions-image {
  flex: 1;
}

.questions-image img {
  width: 100%;
  max-width: 350px;
  border-radius: 12px;
}

.questions-content {
  flex: 1;
  text-align: left;
  line-height: 1.6;
}

.questions-content h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #212529;
}

.questions-content p {
  font-size: 1.1rem;
  color: #495057;
}

/* Remove extra spacing at the bottom of the page */
.pricing-page,
.questions-section {
  margin-bottom: 0;
  padding-bottom: 0;
}

/* ---------------------------
   MOBILE ADJUSTMENTS (max-width: 575.98px)
---------------------------- */
@media (max-width: 450px){
  .pricing-page {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  /* Global typography adjustments for headings & paragraphs */
  h1 {
    font-size: 2rem !important;
    font-weight: 600 !important;
    line-height: 1.3 !important;
  }
  h2 {
    font-size: 1.7rem !important;
    font-weight: 600 !important;
    line-height: 1.3 !important;
  }
  h3 {
    font-size: 1.3rem !important;
    font-weight: 600 !important;
    line-height: 1.3 !important;
  }
  h4 {
    font-size: 1.1rem !important;
    font-weight: 600 !important;
    line-height: 1.3 !important;
  }
  p {
    font-size: 1rem !important;
    line-height: 1.5 !important;
  }

  /* Pricing Top Section: full width background with no rounded corners */
  .pricing-top-section {
    border-radius: 0;
    padding: 1.5rem 3rem;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
  .pricing-top-section h1 {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.3;
  }
  .pricing-top-section p {
    font-size: 1rem;
    line-height: 1.5;
  }

  /* Pricing Section */
  .pricing-section {
    padding: 0 0;
    margin: 1.5rem auto;
  }
  .pricing-title-page {
    font-size: 1.2rem;
    color: #6c757d;
    font-weight: bold;
    margin-bottom: 0.5rem;
    letter-spacing: 0.05rem;
  }
  .pricing-options {
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }
  .pricing-card {
    width: 100%;
    border-radius: 0;
    padding: 1.5rem;
    text-align: center;
  }
  .pricing-card h3 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .pricing-card p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  .pricing-card ul li {
    font-size: 0.9rem;
    line-height: 1.4;
  }
  .pricing-card .price {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1rem 0;
  }
  .pricing-card button {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }
  .pricing-icon {
    text-align: center;
    margin-bottom: 1rem;
  }
  .pricing-icon i {
    font-size: 2rem;
  }
  .pricing-card,
  .pricing-card.premium {
    width: 100%;
    position: relative;
    border-radius: 0;
  }

  /* Why Us Section */
  .why-us-section {
    padding: 2rem 1rem;
    border-radius: 0;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .why-us-title {
    font-size: 1rem;
    font-weight: 600;
  }
  .why-us-section h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  .why-us-cards {
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }
  .why-us-card {
    width: 100%;
    border-radius: 0;
    padding: 0;
    text-align: center;
    margin-bottom: 0;
  }
  .why-us-card h3 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  .why-us-card p {
    font-size: 1rem;
    line-height: 1.5;
  }
  .icon-circle-pricing {
    width: 60px;
    height: 60px;
    font-size: 1.2rem;
    margin: 0 auto 0.8rem;
  }
  .icon-circle-pricing svg {
    font-size: 1rem;
  }

  /* Unlock Potential Section: stack image above content */
  .unlock-potential-section {
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;
  }
  .unlock-potential-image {
    order: -1;
    width: 100%;
    margin-bottom: 0;
    text-align: center;
  }
  .unlock-potential-image img {
    width: 100%;
    max-width: 300px;
    border-radius: 0;
  }
  .unlock-potential-content {
    text-align: center;
  }
  .unlock-potential-content ul,
  .unlock-potential-content li,
  .unlock-potential-content li span {
    text-align: left;
  }
  .unlock-potential-content h2 {
    font-size: 1.2rem !important;
    font-weight: 700 !important;
    margin-bottom: 1rem;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .unlock-potential-content ul li {
    font-size: 1rem;
    line-height: 1.5;
  }
  .unlock-potential-content ul li i {
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }

  /* Questions Section: stack image above text */
  .questions-section {
    flex-direction: column;
    align-items: center;
    padding: 1rem 2rem;
  }
  .questions-image {
    order: -1;
    width: 100%;
    margin-bottom: 1rem;
    text-align: center;
  }
  .questions-image img {
    width: 100%;
    max-width: 300px;
    border-radius: 0;
  }
  .questions-content {
    text-align: center;
  }
  .questions-content h2 {
    font-size: 1.2rem !important;
    font-weight: 700 !important;
    margin-bottom: 1rem;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .questions-content p {
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* -------------------------------------
   Additional Breakpoint Adjustments (up to 767px and from 768px)
------------------------------------- */

/* For screens up to 767px (mobile & small tablets) */
@media (max-width: 767px) {
  /* Why Us Cards: vertical stacking */
  .why-us-cards {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  /* Unlock Potential Section: image above content */
  .unlock-potential-section {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  /* Questions Section: image above text */
  .questions-section {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

/* For screens 768px and above (desktop and larger tablets) */
@media (min-width: 768px) {
  /* Why Us Cards: horizontal stacking with no wrapping */
  .why-us-cards {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  /* Unlock Potential Section: image beside content */
  .unlock-potential-section {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  /* Questions Section: image beside text */
  .questions-section {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .pricing-section {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}


/* --- NEW: Ensure the pricing-top-section shows side gaps on medium screens --- */
@media (min-width: 768px) and (max-width: 1199px){
  .pricing-top-section {
    width: calc(100% - 2rem) !important; /* leaves 2rem gutter on each side */
  }
}

@media (min-width: 1200px){
  .pricing-top-section {
    width: 1176px; /* leaves 2rem gutter on each side */
  }
}