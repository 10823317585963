.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 1rem;
  background-color: #ffffff;
}

.reset-password-card {
  max-width: 500px;
  width: 100%;
  background-color: #ffe8d6;
  border-radius: 16px;
  padding: 2.5rem;
  text-align: center;
}

.reset-password-card h2 {
  font-size: 2.2rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 1rem;
}

.reset-password-card p {
  font-size: 1.1rem;
  color: #495057;
  margin-bottom: 1.5rem;
}

.reset-password-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.reset-password-form input {
  width: 100%;
  max-width: 400px;
  padding: 0.8rem 1rem;
  border: 1px solid #ced4da;
  border-radius: 8px;
  font-size: 1rem;
}

.reset-password-form button {
  background-color: var(--travel-color);
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 0.9rem 2rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reset-password-form button:hover {
  background-color: #e64e2d;
}

.reset-password-form .error-text {
  color: #dc3545;
  font-size: 0.95rem;
}

.reset-password-form .success-text {
  color: #198754;
  font-size: 0.95rem;
}

@media (max-width: 767px) {
  .reset-password-card {
    padding: 2rem 1.2rem;
    border-radius: 0;
  }

  .reset-password-card h2 {
    font-size: 1.8rem;
  }

  .reset-password-card p {
    font-size: 1rem;
  }

  .reset-password-form input,
  .reset-password-form button {
    font-size: 1rem;
  }
}
