.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background-color: #ffffff;
  padding: 2rem;
}

.forgot-password-card {
  background-color: #ffe8d6;
  padding: 2.5rem;
  border-radius: 16px;
  max-width: 460px;
  width: 100%;
  text-align: center;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
}

.forgot-password-card h2 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #212529;
}

.forgot-password-card p {
  font-size: 1rem;
  color: #495057;
  margin-bottom: 1.5rem;
}

.forgot-password-card input {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1.2rem;
  border: 1px solid #ced4da;
  border-radius: 8px;
  font-size: 1rem;
}

.btn-request-reset {
  width: 100%;
  padding: 0.9rem;
  font-size: 1.1rem;
  font-weight: bold;
  background-color: var(--travel-color);
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-request-reset:hover {
  background-color: #e64e2d;
}

.success-text {
  margin-top: 1rem;
  color: #198754;
  font-weight: 500;
}

.error-text {
  margin-top: 1rem;
  color: #dc3545;
  font-weight: 500;
}

.back-to-login {
  margin-top: 2rem;
  display: inline-block;
  font-size: 0.95rem;
  color: #6c757d;
  text-decoration: underline;
  cursor: pointer;
}
