/* Overall page styling */
.sign-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  background-color: #ffffff; /* White background */
}

/* Title styling */
.sign-in-title {
  font-size: 40px;
  font-weight: bold;
  color: #212529; /* Dark gray */
  margin-bottom: 1.5rem; /* Reduced space below the title */
  text-align: center;
}

/* Form styling */
.sign-in-form {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Space between form elements */
  width: 100%;
  max-width: 400px; /* Restrict the width */
  padding: 0;
  margin: 0;
}

.sign-in-form input {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid #dfdcdc; /* Light gray border */
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease;
}

.sign-in-form input:focus {
  border-color: #ff5733; /* Green border on focus */
}

/* Form options (checkbox and forgot password link) */
.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  color: #495057; /* Medium gray */
}

.form-options label {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Space between checkbox and text */
  white-space: nowrap; /* Prevent text from wrapping */
}

.forgot-password {
  color: #cc4c2f; /* Keep the color */
  text-decoration: underline; /* Add underline */
  font-weight: normal; /* Remove bold */
}

.forgot-password:hover {
  text-decoration: underline;
}

/* Button styling */
.btn-sign-in {
  background-color: #ff5733; /* travel color*/
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-sign-in:hover {
  background-color: #e64e2d; /* Darker green on hover */
}

/* Error message */
.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 1rem;
}

/* Adjust spacing */
.sign-in-form .btn-sign-in {
  margin-top: 1rem; /* Reduced space above the button */
  margin-bottom: 0; /* Removed unnecessary bottom margin */
}

.form-options input[type="checkbox"] {
  width: 1.2rem; /* Increase width */
  height: 1.2rem; /* Increase height */
  cursor: pointer; /* Add pointer cursor for better UX */
}
/* Sign-Up Prompt Styling */
.sign-up-prompt {
  font-size: 0.9rem; /* Match the site's normal text size */
  color: #212529; /* Dark gray, consistent with other text */
  text-align: center;
  margin-top: 1rem; /* Add spacing above the prompt */
}

/* Sign-Up Link Styling */
.sign-up-link {
  color: #cc4c2f; /* Highlight color for the link */
  font-weight: normal; /* Match the rest of the text */
  text-decoration: none; /* Remove underline by default */
  text-decoration: underline; /* Add underline on hover */
}
