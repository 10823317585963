/* Normal top header with the logo (scrolls away) */
.get-results-header {
  background-color: #fff;
  padding: 15px 0;
  margin-bottom: 20px;
  /* optional styling if needed (box-shadow, etc.) */
}

/* The pinned bar for the "latest result" */
.fixed-dynamic-result-bar {
  position: fixed;
  /* remove top:0 here, because now we set top in JS. */
  left: 0;
  width: 100%;
  height: 35px;
  /* Start transparent, but we will fade to white in .bg-white */
  background-color: transparent;
  z-index: 9999;
  
  display: flex;
  align-items: center;
  justify-content: center;

  /* Transition BOTH the background AND the top property 
     so that movement is smoother. 
     You can fine-tune durations to taste: 
     e.g. top 0.2s, background-color 0.3s, etc. */
  transition: top 0.1s ease, background-color 0s ease;
}

/* Once the logo is fully gone, we add this class:
   background changes to white */
.fixed-dynamic-result-bar.bg-white {
  background-color: #fff;
}

/* The dynamic text itself */
.header-dynamic-result {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  color: #333;
  white-space: nowrap;
}

/* Sample size for the flag icon */
.header-flag {
  width: 20px;
  height: 20px;
}


/* When the user is at the top, shift the bar down (e.g. 20px) */
.fixed-dynamic-result-bar.down-shift {
  top: 20px;  /* tweak the offset as desired */
}


/* The "fade-in-down" class triggers the keyframe animation. */
.fade-in-down {
  animation: fadeInDown 1.5s forwards ease;
}

@media (max-width: 768px) {
  .fixed-dynamic-result-bar {
    /* Remove any direct top border here if you had it before */
    border-top: none;
    position: relative; /* Ensures the pseudo-element positions within this container */
  }

  .fixed-dynamic-result-bar::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); 
    width: 90%;           /* the line is only 90% of the container */
    max-width: 600px;     /* optional max width for narrower lines on large mobiles */
    border-top: 1px solid #ccc;
  }
}


@keyframes fadeInDown {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}