/* About Page Styling */
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 1rem;
  background-color: #ffffff;
}

/* Top Section */
.about-top-section {
  max-width: 1140px;
  background-color: #ffe8d6;
  padding: 1.5rem 2.5rem;
  border-radius: 16px;
  text-align: center;
  margin-bottom: 3rem;
  margin-left: 1rem ;
  margin-right: 1rem ;
}

.about-top-section h1 {
  font-size: 3rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 1.2rem;
}

.about-top-section p {
  font-size: 1.3rem;
  color: #495057;
  line-height: 1.6;
}

/* Mission Section */
.mission-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  margin: 2rem auto;
  gap: 2rem;
  padding: 0 1rem;
}

.mission-image {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.mission-image img {
  width: 100%;
  max-width: 350px;
  border-radius: 16px;
}

.mission-content {
  flex: 2;
  text-align: left;
  line-height: 1.6;
}

.mission-content h4 {
  font-size: 1.2rem;
  color: #6c757d;
  font-weight: bold;
  margin-bottom: 0.5rem;
  letter-spacing: 0.05rem;
}

.mission-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #212529;
}

.mission-content p {
  font-size: 1.1rem;
  color: #495057;
}

/* Why Our Test Stands Out Section */
.test-stands-out {
  max-width: 1140px;
  width: 100%;
  margin: 2rem auto ;
  padding: 2.5rem;
  background-color: #F5F6FA;
  border-radius: 16px;
  text-align: center;
  
}

.test-stands-out .section-title {
  font-size: 1.5rem;
  color: #6c757d;
  margin-bottom: 0.5rem;
}

.test-stands-out .section-highlight {
  font-size: 2.5rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 2rem;
}

.benefits-container {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.benefit-item {
  flex: 1;
  max-width: 220px;
  text-align: center;
}

.benefit-icon {
  font-size: 2.5rem;
  color: #198754;
  margin-bottom: 1rem;
}

.benefit-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 0.5rem;
}

.benefit-description {
  font-size: 1rem;
  color: #495057;
}

/* Commitment Section */
.commitment-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1140px;
  margin: 2rem auto;
  gap: 2rem;
  padding: 0 1rem;
}

.commitment-image {
  flex: 1;
}

.commitment-image img {
  width: 100%;
  max-width: 350px;
  border-radius: 16px;
}

.commitment-content {
  flex: 2;
  text-align: left;
  line-height: 1.6;
}

.commitment-title {
  font-size: 1.2rem;
  color: #6c757d;
  font-weight: bold;
  margin-bottom: 0.5rem;
  letter-spacing: 0.05rem;
}

.commitment-content h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 1rem;
}

.commitment-content p {
  font-size: 1.1rem;
  color: #495057;
}

/* Community Section */
.community-section {
  max-width: 1140px;
  text-align: center;
  margin: 0 auto 0;
  padding: 2rem 3rem;
  background-color: #F5F6FA;
  border-radius: 16px;
}

.community-section h4 {
  font-size: 1.2rem;
  color: #6c757d;
  font-weight: bold;
  margin-bottom: 0.5rem;
  letter-spacing: 0.05rem;
}

.community-section h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 1.5rem;
}

.community-section p {
  font-size: 1.1rem;
  color: #495057;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.btn-join {
  padding: 1rem 2rem;
  background-color: var(--travel-color);
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-join:hover {
  background-color: #e64e2d;
}

.about {
  margin-bottom: 0;
  padding-bottom: 0;
}

.icon-circle-about {
  width: 70px;
  height: 70px;
  background-color: var(--travel-color);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 0.8rem;
}

.icon-circle-about svg {
  font-size: 1.3rem; /* Adjust this value to decrease/increase the icon size */
}

/* Mobile Adjustments */
@media (max-width: 767px) {
  /* Top Section Mobile Adjustments */
  .about-top-section {
    border-radius: 0;
    padding: 1.5rem 1rem;
    width: 100vw;
    margin-left: calc(-1 * 1rem);
    margin-right: calc(-1 * 1rem);
  }

  .about-top-section h1 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .about-top-section p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    width: 100%;
    margin: 0 auto;
  }

  /* Mission Section Mobile Adjustments */
  .mission-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
  }

  .mission-image {
    width: 100%;
    margin-bottom: 1rem;
    justify-content: center; /* Center horizontally */
  }

  .mission-image img {
    width: 100%;
    height: auto;
  }

  .mission-content {
    width: 100%;
  }

  .mission-content h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .mission-content h2 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 0.8rem;
  }

  .mission-content p {
    font-size: 1rem;
    line-height: 1.5;
  }

  /* Commitment Section Mobile Adjustments */
  .commitment-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
  }

  .commitment-image {
    width: 100%;
    margin-bottom: 1rem;
  }

  .commitment-image img {
    width: 100%;
    height: auto;
  }

  .commitment-content {
    width: 100%;
  }

  .commitment-content h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .commitment-content h2 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 0.8rem;
  }

  .commitment-content p {
    font-size: 1rem;
    line-height: 1.5;
  }

  /* Test Stands Out Section Mobile Adjustments */
  .test-stands-out {
    width: 100vw;
    margin-left: calc(-1 * 1rem);
    margin-right: calc(-1 * 1rem);
    padding: 2rem 1rem;
    border-radius: 0;
    text-align: center;
  }

  .test-stands-out .section-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0.8rem;
  }

  .test-stands-out .section-highlight {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1.2rem;
  }

  .benefits-container {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .benefit-item {
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .benefit-title {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .benefit-description {
    font-size: 0.9rem;
    line-height: 1.4;
  }

  /* Community Section Mobile Adjustments */
  .community-section {
    width: 100vw;
    margin-left: calc(-1 * 1rem);
    margin-right: calc(-1 * 1rem);
    padding: 2rem 1rem;
    border-radius: 0;
    text-align: center;
  }

  .community-section h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .community-section h2 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .community-section p {
    font-size: 1rem;
    line-height: 1.5;
  }
}
