/* ======================================
   Personality Report Styles
====================================== */

/* =======================
   1) CORE LAYOUT
======================= */
.report-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.primary-container {
  width: 95%;
  max-width: 1140px;
  margin: 0rem auto;
  padding: 2rem;
  background: #ffe8d6;
  border-radius: 15px;
}

.primary-split {
  display: flex;
  gap: 2rem;
}

/* =======================
   2) LEFT SECTION
======================= */
.left-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding-right: 2rem;
}

.report-title h1 {
  font-size: 2rem;
  color: #2a2a2b;
  line-height: 1.2;
  font-weight: 700;
  margin: 0 0 1rem 0;
}

.benefits-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.benefits-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.benefits-list li svg {
  margin: 0.1rem 0.6rem 0 0;
  flex-shrink: 0;
}

.benefits-list strong {
  color: #2a2a2b;
  font-weight: 600;
  font-size: 1.1rem;
  display: block;
}

.benefits-list p {
  color: #2a2a2b;
  font-size: 0.95rem;
  margin: 0 0 0;
}

/* =======================
   3) RIGHT SECTION
======================= */
.right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.results-image {
  max-width: 100%;
  height: auto;
  display: block;
}

/* =======================
   4) ASSESSMENT FEE BOX
======================= */
.assessment-fee-box {
  background: #ffffff;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  gap: 0.15rem;
}

.assessment-fee-box svg {
  margin: 0.1rem 0.6rem 0 0;
  flex-shrink: 0;
}

.assessment-fee-box strong {
  font-weight: 600;
  color: #2a2a2b;
  font-size: 1.1rem;
  display: block;
  margin-bottom: 0.2rem;
}

.assessment-fee-box p {
  color: #2a2a2b;
  font-size: 0.95rem;
  margin: 0;
}

/* =======================
   5) UPDATED CTA SECTION
======================= */
.cta-container-outer {
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  padding: 0rem;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.cta-left-container {
  background-color: #F5F6FA;
  padding: 2rem;
  border-radius: 15px;
  flex: 1;
  width: 100%;
}

.cta-container {
  background: transparent !important; /* Force no background */
  padding: 20px 24px;
  border-radius: 12px;
  text-align: center;
  max-width: 1185px;
  width: 100%;
}

.cta-left, .cta-right {
  flex: 1;
}

.cta-right {
  flex: 1;
  padding: 1rem;
  background: none; /* remove background */
  border-radius: 8px;
  border-left: none; /* optionally remove separation line */
}

/* Force left alignment for “What you’ll get” */
.cta-right .what-you-get,
.cta-right .what-you-get li,
.cta-right .what-you-get li span {
  text-align: left;
  align-items: flex-start;
}

/* =======================
   6) FEATURE LIST STYLES
======================= */
.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.feature-icon {
  flex-shrink: 0;
  margin-top: 0.3rem;
}

.feature-text {
  font-size: 1rem;
  color: #2a2a2b;
  line-height: 1.4;
}

/* =======================
   7) CTA BUTTON
======================= */
.cta-button {
  background: #ff5733; /* Vibrant green */
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 14px 20px;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  border: none;
  cursor: pointer;
  margin-top: 8px; /* Space from the pricing */
}

.cta-button:hover {
  background: #e64e2d; /* Slightly darker green on hover */
}

/* =======================
   8) TRUST INDICATORS
======================= */
.trust-indicators {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;
  font-size: 0.85rem;
  color: #666;
}

.trust-item {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.8rem;
  font-weight: 400;
}

/* Specific Styling for Lock Icon */
.lock-icon {
  fill: #d5d5d673;
  stroke: none;
}

/* Trust indicator items */
.trust-indicator {
  display: flex;
  align-items: center;
  gap: 6px;
}

.trust-indicator span {
  font-weight: 500;
}

/* =======================
   9) PRICING SECTION
======================= */
.pricing {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers everything */
  padding: 16px;
  border-radius: 14px;
  background: white;
  border: 1.8px solid #787878 !important; /* Grey border */
  width: 100%;
}

/* Properly align title, divider, and price */
.pricing-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 16px;
}

.pricing-title {
  font-size: 1rem;
  font-weight: bold;
  color: #2a2a2b;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1; /* Allows it to expand and push the divider */
  white-space: nowrap;
}

/* Pricing Text */
.pricing strong {
  font-size: 1.8rem;
  font-weight: bold;
  color: #007f4e; /* Dark green */
}

/* Vertical Grey Line */
.price-divider {
  width: 2px;
  height: 60px;
  background: #d1d5db;
  margin: 0 15px; /* Spacing between title and price */
}

/* Container for the price & divider */
.price-container {
  display: flex;
  align-items: center;
  gap: 15px; /* Space between vertical line & price */
  align-self: flex-end;
  justify-content: space-between;
  width: auto;
  padding: 5px 10px;
  flex-direction: column;
}

/* Price Styling */
.price-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  margin-top: -5px; /* Move price slightly up */
}

.price-section {
  background: white;
  padding: 12px;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #2a2a2b;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
}

/* Price Text */
.price {
  font-size: 1.8rem;
  font-weight: bold;
  color: #000000; /* Dark green */
}

/* =======================
   10) EXTRA ELEMENTS
======================= */
.mobile-results-image {
  display: none;
}

/* CTA Split Layout */
.cta-split {
  display: flex;
  gap: 2rem;
  width: 100%;
}

.cta-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Title & Text for “What you’ll get” */
.what-you-get-title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #2a2a2b;
  margin-bottom: 1.25rem;
  text-align: left; /* Ensure left alignment */
}

.what-you-get {
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: flex-start;
  margin-top: 0px;
}

.what-you-get li {
  font-family: 'Poppins', sans-serif;
  color: #424242;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  font-size: 1.1rem;
}

/* Adjust text position within “What you’ll get” */
.what-you-get li span {
  display: flex;
  align-items: center;
  margin-top: 10px; /* Moves text slightly up */
}

.feature-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #27AE60;
}

/* Orange Circle Icon in “What you’ll get” */
.loader-icon-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ff5733;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem auto; /* centers horizontally, plus 1rem bottom gap */
  flex-shrink: 0;
  margin-top: 3px;
  margin-left: -35px;
}

.loader-icon-circle svg {
  width: 45%;
  height: 45%;
  fill: #fff; /* White icon color inside the circle */
}

/* =======================
   11) LIVE COUNTER
======================= */
.digit-box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0px 3px;
  font-size: 1.3rem;
  font-weight: bold;
  min-width: 25px;
  text-align: center;
  margin: 0 2px;
}

.counter-wrapper {
  display: inline-flex;
  background: #EAECEF;
  padding: 3px 3px;
  border-radius: 5px;
  align-items: center;
}

/* Optional CTA Title */
.cta-title {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 10px;
  text-align: left;
  color: #2a2a2b;
}

/* Style for the entire counter section */
.reports-counter {
  font-size: 1rem;
  display: flex;
  align-items: center;
}

/* Move "reports ordered!" to the right and change its color */
.reports-text {
  margin-left: 10px; /* Move it to the right */
  font-family: 'Poppins', sans-serif;
  color: #424242;
  font-weight: 530; /* Make it slightly bolder */
}

/* Minimize font size of "for 7-day report" */
.price-subtext {
  font-size: 0.75rem;
  color: #666;
  margin-top: 1px; /* Reduce spacing */
}
/* =======================
   As Seen On Section
====================== */
.as-seen-on {
  margin-top: 2rem;
  text-align: center;
}

/* The line with text, using pseudo-elements to create the split line */
.line-with-text {
  display: flex;
  align-items: center;
  margin: 2rem 0;
  color: #5e5e5e;
  font-weight: 500;
}
.line-with-text::before,
.line-with-text::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc; /* light grey line */
}
.line-with-text::before {
  margin-right: 1rem;
}
.line-with-text::after {
  margin-left: 1rem;
}

/* Logos container: always in one row and responsive */
.logos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 150px); /* 10px margin on each side */
  flex-wrap: nowrap;
  margin: 0 auto;
}

/* Logo styling */
.as-seen-logo {
  width: 100%;
  height: auto;
}
.as-seen-logo-big {
  width: 100%;
  height: auto;
}

/* =======================
   12) REVIEWS SECTION
======================= */
.reviews-section {
  width: 90%; /* Match the CTA section width */
  max-width: 1140px; /* Match the CTA max-width */
  margin: 1rem auto 0;
  padding: 0; /* Remove additional padding */
}

.reviews-container {
  padding: 0;
}

.reviews-title {
  text-align: left;
  font-size: 1.8rem;
  color: #2d3436;
  margin: 0 0 1rem 0;
  font-weight: 700;
  padding: 0;
  line-height: 1.3;
}

.stars-container {
  display: flex;
  align-items: center;
  gap: 0px;
}

.stars-icon {
  width: 18px;
  height: auto;
  display: block;
  margin-bottom: 0.8rem;
}

.stars-iconH {
  width: 25px;
  height: auto;
  display: block;
  margin-bottom: 0.8rem;
}

.review-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
}

/* Latest Results specific styles */
.latest-results .review-item {
  flex-direction: row; /* Horizontal layout */
  align-items: center; /* Vertical center alignment */
  gap: 1rem;
  padding: 1rem;
}

.latest-results .result-text {
  flex-grow: 1;
}

.review-item {
  background: #F5F6FA;
  padding: 1.5rem;
  border-radius: 8px;
  border: 1px solid #ecedee;
  display: flex;
  align-items: left;
  gap: 0;
  flex-direction: column;
}

/* Trust Icons - Resize to Match Reference */
.trust-icon {
  width: 14px;
  height: 14px;
  stroke: #d8d8d873;
  stroke-width: 1;
}

/* Checkmark Specific Styling */
.trust-icon.checkmark {
  fill: #95969673;
  stroke: none;
}

.review-text {
  font-size: 1rem;
  line-height: 1.6;
  color: #000000;
  margin: 0;
}

.trust-badges {
  margin-top: 1rem;
  padding-top: 1rem;
  /*border-top: 1px solid #eee;*/
}

/* Ensure "Latest Results" matches regular text */
.latest-results .reviews-title {
  font-size: 1rem;
  font-weight: normal;
  color: inherit;
  margin: 0;
  padding: 0;
  text-align: left;
  margin-bottom: 20px;
}

.latest-results {
  margin-top: 0;
  padding-top: 0;
}

.result-item {
  font-size: 0.95rem;
  margin: 0.8rem 0;
  color: #555;
}

/* New CSS for the circular flag images */
.circle-flag {
  width: 32px; 
  height: 32px;
  display: block;
}

/* =======================
   13) FULL‑WIDTH CTA
======================= */
.cta-fullwidth {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #F5F6FA;
  padding: 2rem;
  margin-top: 2rem;
  border-radius: 15px;
  box-sizing: border-box;
  gap: 5rem;
  margin-bottom: 2rem;
}

.cta-full-left {
  flex: 1.2;
}

.cta-full-right {
  flex: 1.5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.cta-full-right .pricing {
  max-width: unset;
  width: 100%;
}
.cta-input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}
.cta-button.disabled {
  opacity: 0.7;
  pointer-events: none;
  cursor: wait;
}

.spinner {
  display: inline-block;
  margin-right: 8px;
  width: 14px;
  height: 14px;
  border: 2px solid transparent;
  border-top-color: white;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
  vertical-align: middle;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* =======================
   14) MEDIA QUERIES
======================= */
@media (max-width: 424px) {
  /* Stack pricing items vertically */
  .pricing-content {
    flex-direction: column;
    align-items: center;
    padding: 0 10px; /* Adjust padding if needed */
  }
  /* Transform vertical divider to horizontal divider */
  .price-divider {
    width: 100%;
    height: 1px;       /* Make it a thin line */
    margin: 5px 0;    /* Provide vertical spacing */
  }
  /* Ensure price info stays centered */
  .price-info {
    width: 100%;
    text-align: center;
    margin-top: 0;
  }
  .price {
    font-size: 1.5rem; /* Adjust font sizes as needed */
  }
  .price-subtext {
    font-size: 0.75rem;
  }
  .pricing-title {
    width: 100%;
    text-align: left;
    align-items: flex-start;
  }
}


/* --- Extra Small Devices (max-width: 449.98px) --- */
@media (max-width: 449.98px) {
  .primary-container {
    width: 100%;
    border-radius: 0;
    padding: 1.5rem;
  }
  .report-title h1 {
    font-size: 1.5rem  !important;
    font-weight: bold !important;
  }
  .cta-container-outer,
  .cta-container {
    padding: 0 !important;
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
  /* Make the left container full width with no border radius */
  .cta-left-container {
    border-radius: 0;
  }
  .cta-fullwidth {
    width: 100vw !important;
    margin-left: calc(-50vw + 50%) !important;
    border-radius: 0 !important;

  }
}

/* --- Small Devices (max-width: 575.98px) --- */
@media (max-width: 575.98px) {
  .report-page-wrapper {
    /* Add top padding to push the content down */
    padding-top: 20px; /* Adjust the value if needed */
  }
}

/* --- Medium Devices (max-width: 768px) --- */
@media (max-width: 768px) {
  .primary-split {
    flex-direction: column;
  }
  
  .left-section {
    padding-right: 0;
  }
  
  .report-title h1 {
    font-size: 1.75rem !important;
    font-weight: bold !important;
    text-align: center;
  }
  .cta-container {
    flex-direction: column;
    background: transparent !important; /* Force no background */
  }
  .cta-right {
    padding-left: 0;
    border-left: none;
    border-top: 2px solid #f0f0f0;
    padding-top: 2rem;
  }
  .right-section {
    display: none;
  }
  .mobile-results-image {
    display: block;
    margin: 1rem 0;
  }
  .as-seen-logo {
    width: 60px;
  }
  .as-seen-logo-big {
    width: 45px;
  }
  .review-grid {
    display: flex !important;
    flex-direction: column;
    gap: 1.5rem;
  }
}

/* --- Additional Shared Responsive Adjustments (max-width: 768px) --- */
@media (max-width: 768px) {
  .cta-split {
    flex-direction: column;
  }
  .cta-right {
    padding: 1rem 0;
  }
  .cta-container {
    flex-direction: column;
    padding: 1rem;
  }
  .cta-left-container,
  .cta-right {
    padding: 1rem;
  }
  .cta-container-outer {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0; /* Adjust padding if necessary */
  }
  .reviews-title {
    font-size: 1.6rem;
  }
  .trust-badges {
    flex-direction: column;
    text-align: center;
  }
  .trust-metric {
    flex-direction: column;
  }
  .cta-fullwidth {
    flex-direction: column;
    gap: 2rem; /* adjust as needed */
  }
}
@media (min-width: 769px) {
  .as-seen-logo {
    width: 80px;
  }
  .as-seen-logo-big {
    width: 60px;
  }
  .review-grid {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 1.5rem;
  }
  .reviews-title {
    font-size: 1.6rem;
  }
}

/* --- Large Devices (min-width: 992px) --- */
@media (min-width: 992px) {
  .footer-container {
    max-width: 1200px !important;
    margin: 0 auto;
  }
}
