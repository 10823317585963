/* =============================================
   GLOBAL VARIABLES & THEME COLORS
   ============================================= */
   :root {
    --travel-color: #ff5733; /* Modern orange representing energy and adventure */
    --hover-color: #e64e2d; /* Slightly darker hover color */
    --highlight-color: #cc4c2f; /* A darker orange for highlights */
  }
  
  /* =============================================
     NAV LINK HOVER & ACTIVE STYLES
     ============================================= */
  .nav-link-hover {
    position: relative;
    display: inline-block;
    font-weight: bold;
    transition: color 0.3s ease;
    text-decoration: none;
  }
  
  .nav-link-hover:hover {
    color: var(--hover-color);
  }
  
  .nav-link-hover.active {
    color: var(--travel-color);
  }
  
  .nav-link-hover::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0;
    height: 2px;
    background-color: var(--travel-color);
    transition: width 0.3s ease;
  }
  
  .nav-link-hover:hover::after,
  .nav-link-hover.active::after {
    width: 100%;
  }
  
  /* =============================================
     BUTTON STYLES
     ============================================= */
  .btn-travel,
  .btn-outline-travel {
    font-weight: bold;
    padding: 0.4rem 1.5rem;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .btn-travel {
    background-color: var(--travel-color);
    color: #fff;
    border: none;
  }

  .btn-travel:hover {
    background-color: var(--hover-color);
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .btn-outline-travel {
    color: var(--travel-color);
    border: 2px solid var(--travel-color);
  }
  
  .btn-outline-travel:hover {
    background-color: var(--travel-color);
    color: #fff;
  }
  
  /* Slightly larger "Start Discovery" button */
  .btn-larger {
    padding: 0.4rem clamp(1rem, 3vw, 2rem);
  }
  
  /* =============================================
     LOGO & BRANDING
     ============================================= */
  .brand-logo {
    height: 40px;
    width: auto;
  }
  
  .navbar-brand {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .brand-text {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
  }
  
  .navbar-brand span {
    font-size: 1rem;
    font-weight: bold;
    color: #212529;
  }
  
  .navbar-brand .domain {
    font-weight: normal;
    color: #6c757d;
  }
  
  .brand-text small {
    font-size: 0.8rem;
    font-weight: normal;
    color: #6c757d;
    margin-top: -4px;
  }
  
  /* =============================================
     HEADER / NAVBAR
     ============================================= */
  .custom-header {
    background-color: transparent;
    box-shadow: none;
    border-bottom: none;
  }
  /* Custom Hamburger/X Icon */
.navbar-toggler {
  border: 0 !important;
  padding: 0.25rem;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23ff5733' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  transform: scale(0.8);
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23ff5733' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M6 6L24 24M24 6L6 24'/%3e%3c/svg%3e");
}
.custom-header .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* Full-screen mobile menu */
@media (max-width: 991.98px) {
  
  .navbar-collapse {
    position: fixed;
    top: 65px; /* Adjust this value to match your header’s height */
    left: 0;
    width: 100vw;
    height: calc(100vh - 65px);
    background: white;
    z-index: 999;
    /* Remove extra padding since the header now provides space */
    padding-top: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .navbar-collapse.collapsing {
    transform: translateX(100%);
  }
  
  .navbar-collapse.show {
    transform: translateX(0);
  }

  .navbar-nav {
    width: 100%;
    text-align: center;
  }

  .nav-item {
    margin: 1.5rem 0; /* Increased space between links */
  }

  .nav-link {
    font-size: 1.2rem;
    padding: 0.5rem 0;
  }

  .nav-link-hover::after {
    display: none; /* Remove underline in mobile menu */
  }

  /* Sign In button adjustments */
  .btn-outline-travel {
    border-width: 1px; 
    margin-top: 2rem;
    padding: 0.75rem 2rem;
    font-size: 1.1rem;
  }

  /* Ensure logo remains visible */
  .custom-header {
    position: relative;
    z-index: 1000;
  }
  
  .navbar-toggler {
    z-index: 1000;
    position: relative;
  }
  .header-container {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    max-width: 1200px ;
  }
  /* Ensure the logo/brand remains left aligned and does not get centered */
  .navbar-brand {
    margin: 0 ;
    text-align: left ;
    flex: 0 0 auto;
  }
  /* Fix the hamburger toggler to have a constant right margin */
  .navbar-toggler {
    margin-right: 1rem ;
    flex-shrink: 0;
  }
  
  
}
@media (min-width: 992px) { /* Applies from laptop screen size and up */
  .custom-header .container {
    max-width: 1200px !important;
  }
  .header-container {
    max-width: 1200px;
    margin: 0 auto;
  }
    /* Force the header container to use the same small‑screen padding */
  .header-container {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    padding-top: 1rem !important;
    max-width: 1200px !important;
  }
  /* Ensure the logo/brand remains left aligned and does not get centered */
  .navbar-brand {
    margin: 0 !important;
    text-align: left !important;
    flex: 0 0 auto;
  }
  /* Fix the hamburger toggler to have a constant right margin */
  .navbar-toggler {
    margin-right: 1rem !important;
    flex-shrink: 0;
  }
}

  /* =============================================
     PINNED BAR (FOR GET-RESULTS)
     ============================================= */
  .get-results-header {
    padding: 1rem 0;
    
  }
  .fixed-dynamic-result-bar {
    position: fixed;
    width: 100%;
    z-index: 999;
  }
  
  /* =============================================
     FOOTER
     ============================================= */
  .footer-section {
    background-color: transparent;
  }
  
  .footer-divider {
    border-top: 1px solid rgba(0, 0, 0, 0.7);
    margin: 1rem 0;
  }
  
  .top-divider {
    margin-top: 0;
  }
  
  .bottom-divider {
    margin-bottom: 1rem;
  }
  

  .disclaimer-toggle {
    text-decoration: underline;
    cursor: pointer;
    font-weight: normal;
    color: #6c757d;
  }
  
  /* =============================================
     FOOTER LINK STYLES
     ============================================= */
  .footer-section ul li a {
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #333333;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-section ul li a:hover {
    color: var(--travel-color);
    text-decoration: underline;
  }
/* New divider styling */
.footer-divider-new {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.7);
  margin: 1rem 0;
}

/* (Optional) Ensure it stays hidden on laptop screens even if rendered */
@media (min-width: 992px) {
  .footer-divider-new {
    display: none;
  }
  .footer-section {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 450px) {
  .footer-section {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}