.invite-page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 1.5rem 4rem;
  min-height: 100vh;
  background-color: #fff;
}

.invite-content {
  max-width: 600px;
  width: 100%;
  background-color: #ffe8d6;
  padding: 2.5rem 2rem;
  border-radius: 16px;
  text-align: center;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.invite-image {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin: 0 auto 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
}

.invite-content h1 {
  font-size: 2.2rem;
  font-weight: 700;
  color: var(--primary-text, #000);
  margin-bottom: 1rem;
}

.invite-subheading {
  font-size: 1.2rem;
  color: var(--muted-text, #888);
  margin-bottom: 1.5rem;
}

.invite-description {
  font-size: 1.1rem;
  color: var(--secondary-text, #444);
  margin-bottom: 2rem;
  line-height: 1.6;
}

.cta-button {
  display: inline-block;
  background-color: var(--travel-color, #F7591F);
  color: #fff;
  padding: 0.9rem 2rem;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 700;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #e64e2d;
}

/* Removed unused styles (platform-buttons, social-btn) */